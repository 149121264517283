import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faInstagram,
  faPinterest,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'
import { FC } from 'react'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { useAppSelector } from '@app/store'
import pxToRem from '@app/ui/utils/pxToRem'

const StyledLink = styled.a`
  font-size: 1.25rem;
  &:not(:first-child) {
    margin-left: 1rem;
  }
  ${media.desktop`
    font-size: 1.5625rem;
    &:not(:first-child) {
      margin-left: 1.5rem;
    }
  `}

  & > svg {
    height: ${pxToRem(25)};
  }

  &:empty {
    margin-left: 0;
  }
`

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 1.25rem;
  color: #ffffff;
  opacity: 0.5;
  ${StyledLink}:hover & {
    color: #00afd9;
    opacity: 1;
  }
`

const iconRefs: { [key: string]: any } = {
  'facebook': faFacebookF,
  'twitter': faTwitter,
  'linkedin': faLinkedinIn,
  'instagram': faInstagram,
  'pinterest': faPinterest,
  'youtube': faYoutube
}

interface SocialNetworkLink {
  url: string
  socialNetwork: string
}

const SocialLinks: FC = () => {
  const { branchSettings, siteSettings } = useAppSelector(state => state.app)
  return (
    <div>
      {(branchSettings?.social ?? siteSettings?.social)?.map((item: SocialNetworkLink, index: number) => (
        <StyledLink key={index} href={item.url} target="_blank">
          <StyledIcon icon={iconRefs[item.socialNetwork]} />
        </StyledLink>
      )
      )}
    </div>
  )
}

export default SocialLinks
