import React, { useEffect } from 'react'
import { GlobalStyle } from '@app/ui/styles/global'
import { GridThemeProvider } from 'styled-bootstrap-grid'
import { ThemeWrapper } from './theme/Context'

import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faMinus,
  faPlus,
  faChevronDown,
  faLongArrowRight as fasLongArrowRight,
  faSignOut,
  faPlay,
  faPause,
  faTimes,
  faInfo,
  faBars,
} from '@fortawesome/pro-solid-svg-icons'
import {
  faHome,
  faHouseUser,
  faExclamationCircle,
  faEnvelope,
  faPhone,
  faTicket,
  faTicketAlt,
  faCog,
  faUserFriends,
  faCalendar,
  faComment,
  faLongArrowRight as farLongArrowRight,
  faEllipsisH,
  faArrowToBottom,
  faExclamation,
  faSuitcase,
  faStore,
  faTable,
  faMapMarkerAlt,
  faExternalLink,
} from '@fortawesome/pro-regular-svg-icons'
import REMScaling from './styles/REMScaling'

import '@fortawesome/fontawesome-free/css/all.css'
import { useDispatch } from 'react-redux'
import { fetchSiteSettings } from '@app/store/slices/app-slice'

library.add(
  faMinus,
  faBars,
  faPlus,
  faChevronDown,
  faSignOut,
  faHome,
  faHouseUser,
  faExclamationCircle,
  faEnvelope,
  faPhone,
  faTicket,
  faTicketAlt,
  faCog,
  faUserFriends,
  faCalendar,
  faComment,
  fasLongArrowRight,
  farLongArrowRight,
  faEllipsisH,
  faArrowToBottom,
  faExclamation,
  faPlay,
  faPause,
  faTimes,
  faSuitcase,
  faStore,
  faTable,
  faInfo,
  faExternalLink,
  faMapMarkerAlt,
)

const gridTheme: any = {}

const Root = ({ children }: { isLoading?: boolean, children: React.ReactChild }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchSiteSettings())
  }, [dispatch])

  return (
    <ThemeWrapper>
      <GlobalStyle />
      <REMScaling>
        <GridThemeProvider gridTheme={gridTheme}>
          {children}
        </GridThemeProvider>
      </REMScaling>
    </ThemeWrapper>
  )
}

export default Root
