import { FC } from 'react'
import FooterBottom, { FooterBottomProps } from './FooterBottom'
import FooterTop, { FooterTopProps } from './FooterTop'
import defaultLogo from '@app/assets/images/logo.png'

export interface FooterProps extends FooterTopProps, FooterBottomProps { }

const Footer: FC<FooterProps> = ({ logo, navitems, colophon, backlink }) => {
  return (
    <div>
      <FooterTop logo={logo ?? defaultLogo.src} navitems={navitems} />
      <FooterBottom colophon={colophon} backlink={backlink} />
    </div>
  )
}

export default Footer
