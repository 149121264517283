import { useUser } from '@app/lib/hooks/useUser'
import { useAppSelector } from '@app/store'
import { useRouter } from 'next/router'

type FooterContentItem = 'email' | 'phone' | 'privacyPolicy' | 'disclaimer'

export const useFooter = () => {
  const router = useRouter()
  const { preview } = router.query
  const { branchSettings, siteSettings } = useAppSelector(state => state.app)
  const { session } = useUser()

  const getScopedContentFactory = (
    session: any,
    preview: boolean,
    branchSettings: any,
    siteSettings: any,
  ) => (
    contentKey: FooterContentItem
  ) => ((session?.isLoggedIn || preview) ? branchSettings?.[contentKey] : siteSettings?.[contentKey]) ?? ''

  const getScopedContent  = getScopedContentFactory(session, preview === 'true', branchSettings, siteSettings)

  const email             = getScopedContent('email')
  const phone             = getScopedContent('phone')
  const privacyPolicy     = getScopedContent('privacyPolicy')
  const disclaimer        = getScopedContent('disclaimer')

  return {
    'logo': siteSettings?.footerLogo?.sourceUrl,
    'navTitle': 'Need help?',
    'navitems': [
      {
        'title': email,
        'url': `mailto:${email}`,
        'icon': 'envelope',
        'type': 'link'
      },
      {
        'title': phone,
        'url': `tel:${phone}`,
        'icon': 'phone',
        'type': 'link'
      },
      ...privacyPolicy ? [{
        'title': 'Privacy policy',
        'url': privacyPolicy,
        'icon': 'long-arrow-right',
        'type': 'link'
      }] : [],
      ...disclaimer ? [{
        'title': 'Disclaimer',
        'url': disclaimer,
        'icon': 'long-arrow-right',
        'type': 'link'
      }] : [],
    ],
    'colophon': siteSettings?.colophon ?? '',
    'backlink': {
      'text': ['', 'Site by atomix'],
      'url': 'https://www.atomix.com.au'
    }
  }
}
