import pxToRem from '@app/ui/utils/pxToRem'
import Link from 'next/link'
import { FC } from 'react'
import styled from 'styled-components'

const FooterBottomContainer = styled.div`
  background: #052948;
`

const FooterBottomSection = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 90rem;
  padding-top: ${pxToRem(8.5)};
  padding-bottom: ${pxToRem(8.5)};
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.75rem;
  @media screen and (min-width: 1040px) {
    flex-direction: row;
    justify-content: space-between;
    padding-left: 5.75rem;
    padding-right: 5.75rem;
    font-size: 0.8125rem;
  }
`

const ColophonText = styled.div`
  color: #e2f1f8;
  opacity: 0.7;
  font-size: ${pxToRem(13)};
`

const BacklinkText = styled.div`
  color: ${props => props.theme.colors.info};
  font-size: ${pxToRem(13)};
`

const BacklinkLink = styled.a`
  color: ${props => props.theme.colors.info};
  &:hover {
    color: #e2f1f8;
  }
`

export interface FooterBottomProps {
  colophon: string
  backlink: {
    text: string[]
    url: string
  }
}

const FooterBottom: FC<FooterBottomProps> = ({ colophon, backlink }) => {
  return (
    <FooterBottomContainer>
      <FooterBottomSection>
        <ColophonText>{colophon}</ColophonText>
        <BacklinkText>
          {backlink.text[0]}{' '}
          <Link href={backlink.url} passHref legacyBehavior>
            <BacklinkLink target="_blank">{backlink.text[1]}</BacklinkLink>
          </Link>
        </BacklinkText>
      </FooterBottomSection>
    </FooterBottomContainer>
  )
}

export default FooterBottom
